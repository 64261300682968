<template>
  <main>
    <trac-loading v-if="isLoading" />
    <div class="flex w-full justify-center mt-16 items-center">
      <div
        ref="plac"
        class="
          max-w-4xl
          auth-shadow
          p-5
          flex-col
          pb-16
          rounded-md
          w-full
          flex
          items-center
          justify-center
        "
      >
        <div class="flex items-center justify-center flex-col mt-5">
          <img src="@/assets/svg/otherLogo.svg" class="w-12 h-12" alt="" />
          <h2 class="text-sm pt-2 font-medium text-primaryBlue tracking-wide">
            traction apps
          </h2>
        </div>
        <trac-validation-observer slim v-slot="{ handleSubmit }">
          <form
            @submit.prevent="handleSubmit(logUserIn)"
            class="max-w-xl ma w-full mt-12"
          >
            <h1 class="text-lg font-medium text-accentDark">Sign in</h1>
            <trac-validation-provider
              name="email"
              rules="required|email"
              v-slot="{ errors }"
            >
              <trac-input
                v-model.trim="loginDetails.email"
                placeholder="Email Address"
                class="mt-5"
                ref="emails"
                type="email"
                tag="div"
              />
              <trac-input-error v-if="errors[0]">{{
                errors[0]
              }}</trac-input-error>
            </trac-validation-provider>
            <trac-validation-provider
              name="password"
              rules="required"
              v-slot="{ errors }"
            >
              <div>
                <trac-input
                  v-model.trim="loginDetails.password"
                  type="password"
                  placeholder="Password"
                  specified="password"
                  class="mt-6"
                  ref="password"
                  tag="div"
                />
              </div>
              <trac-input-error v-if="errors[0]">{{
                errors[0]
              }}</trac-input-error>
            </trac-validation-provider>
            <div class="mt-4 flex items-center">
              <trac-checkbox />
              <p class="text-xs text-primaryGray pl-2">Remember me</p>
            </div>
            <div class="mt-8 flex items-center justify-center flex-col">
              <trac-button buttonType="submit" class="w-full max-w-xs"
                ><div class="py-1">Login</div></trac-button
              >
              <p class="text-xs mt-4">
                Don’t have an account?
                <router-link to="/signup" class="text-primaryBlue font-medium"
                  >Create one.</router-link
                >
              </p>
              <!-- <router-link
                to="/forgot"
                class="mt-3 text-xs text-primaryBlue font-medium"
                >Forgot password?</router-link
              > -->
              <router-link
                to="/forgot-password"
                class="mt-3 text-xs text-primaryBlue font-medium"
                >Forgot password?</router-link
              >
            </div>
          </form>
        </trac-validation-observer>
      </div>
    </div>
  </main>
</template>

<script>
import {
  ADD_AUTH_USER,
  GET_USER_BUSINESS_ID,
  GET_USER_TOKEN,
  GET_LOCAL_DB_DATA,
  SAVE_LOCAL_DB_DATA,
} from "../browser-db-config/localStorage";
import {
  ADD_DATA_TO_DB,
  CLEAR_ALL_DATA_IN_DB,
  CONNECT_TO_DB,
  SAVE_OBJECT_STORE,
  GET_DB_DATA,
} from "../browser-db-config/indexedDB";
import { FETCH_ALL_DATA } from "../browser-db-config/utilityMethods";
import { eventBus } from "./../main";

export default {
  data() {
    return {
      isLoading: false,
      loginDetails: {
        email: "",
        password: "",
      },
      authUserData: {
        userData: null,
        businessData: null,
        tokenTimeStamp: null,
      },
    };
  },
  mounted() {
    if (this.$route.params) {
      this.loginDetails.email = this.$route.params.email;
    }
  },
  methods: {
    async logUserIn() {
      // Clear all data on localStorage
      // localStorage.clear();

      this.isLoading = true;
      await this.$store.dispatch("LOG_USER_IN", this.loginDetails);
      const res = this.$store.getters["GET_LOGIN_RES"];

      if (res.status) {
        // await CLEAR_ALL_DATA_IN_DB();
        this.saveData("traction-app-user-data", res.data);

        // Save the login response
        this.authUserData.userData = res.data;

        await this.fetchBusinessUserDetails();

        // Save data to IndexedDB so as to be used in ServiceWorker
        // const authResDBData = await CONNECT_TO_DB(
        //   "auth_data_db",
        //   1,
        //   "auth_data"
        // );
        // await ADD_DATA_TO_DB(authResDBData, "auth_data", res.data);
        await SAVE_OBJECT_STORE("auth_data_db", "auth_data", res.data);

        // const offlineProductsDB = await CONNECT_TO_DB('offline_db_products', 1, 'products');
      } else {
        // alert(res.message || "Error: Netowrk error occured.");
        eventBus.$emit("trac-alert", {
          message: res.message || "Error: Netowrk error occured.",
        });
        this.isLoading = false;
      }
      this.isLoading = false;
    },
    fetchLoyaltyPointToken() {
      const myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("Content-Type", "application/json;charset=utf-8");
      myHeaders.append("Authorization", "Bearer " + GET_USER_TOKEN());

      // Fetch Token
      fetch(`https://tractionapp-stage.herokuapp.com/auth/loyalty/access`, {
        method: "POST",
        headers: myHeaders,
      })
        .then((res) => res.json())
        .then(async (data) => {
          this.$store.dispatch("DOWNLOAD_CUSTOMERS_POINTS", data);
          FETCH_ALL_DATA();
          await SAVE_OBJECT_STORE(
            "secondary_token_db",
            "secondary_token",
            data.data
          );

          // Check is store credit for buiness is active
          this.$store.dispatch("CHECK_BUSINESS_STORE_CREDIT_IS_ACTIVE");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async fetchBusinessUserDetails() {
      await this.$store.dispatch("FETCH_BUSINESS_USER_DETAILS");
      const res = this.$store.getters["GET_BUSINESS_USER_RES"];

      if (res.status) {
        this.saveData("traction-app-current-user-business-data", res.data);
        this.authUserData.businessData = res.data;

        // Add token for  perssistent login
        this.authUserData.tokenTimeStamp = GET_LOCAL_DB_DATA("auth-token-date");

        // Add auth user to list of auth users
        ADD_AUTH_USER(this.authUserData);

        this.fetchLoyaltyPointToken();

        // Save data to IndexedDB so as to be used in ServiceWorker
        // const businessResDBData = await CONNECT_TO_DB(
        //   "business_data_db",
        //   1,
        //   "business_data"
        // );
        // await ADD_DATA_TO_DB(businessResDBData, "business_data", res.data);
        await SAVE_OBJECT_STORE("business_data_db", "business_data", res.data);

        // this.fetchAllAssets();

        // this.$router.push({ name: "Payments" });
        this.$router.push({ name: "Dashboard" });
      } else {
        eventBus.$emit("trac-alert", {
          message: res.message || "Error: Netowrk error occured.",
        });
        this.isLoading = false;
      }
    },
    saveData(localStorageName, dataToStore) {
      SAVE_LOCAL_DB_DATA(localStorageName, dataToStore);
    },
    fetchAllAssets() {
      // this.$store.dispatch("FETCH_ALL_CUSTOMERS");
      // this.$store.dispatch('FETCH_ALL_CUSTOMERS');
    },
  },
};
</script>

<style></style>
